export enum SortDirection {
	ASC = "ASC",
	DESC = "DESC",
}

export enum ProjectStage {
	PENDING = "PENDING",
	PROGRESS = "PROGRESS",
	COMPLETE = "COMPLETE",
}

export enum QuoteStage {
	ALL = "ALL",
	OPEN = "OPEN", // dummy type used for filtering
	PROGRESS = "PROGRESS",
	COMPLETE = "COMPLETE",
	APPROVED = "APPROVED",
	CLOSED = "CLOSED" // dummy type used for filtering
}

export enum JobStage {
	ALL = "ALL",
	PENDING = "PENDING",
	PROGRESS = "PROGRESS",
	COMPLETE = "COMPLETE",
	INVOICED = "INVOICED",
	ARCHIVED = "ARCHIVED",
}

export enum ProjectSource {
	JOB = "JOB",
	QUOTE = "QUOTE",
}

export enum Status {
	IDLE = "IDLE",
	LOADING = "LOADING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR"
}

export enum UnitsType {
	METRIC = "METRIC",
	IMPERIAL = "IMPERIAL",
}

export enum KeySettingsType {
	DETAILED = "DETAILED",
	SUMMARY = "SUMMARY",
}

export enum LogsFilterRange {
	TODAY = "TODAY",
	WEEK = "WEEK",
	MONTH = "MONTH",
	CUSTOM = "CUSTOM"
}

export enum VersionLabel {
	DRAFT = "DRAFT",
	LATEST = "LATEST",
	SUPERSEDED = "SUPERSEDED",
	COMPLETED = "COMPLETED",
	REVERTABLE = "REVERTABLE",
}

export enum TreeVersionModal {
	NONE = "NONE",
	COPY = "COPY",
	COMPLETE = "COMPLETE",
	REVERT = "REVERT"
}

export enum TreeDrawingModal {
	NONE = "NONE",
	DELETE = "DELETE",
	MOVE = "MOVE",
	RENAME = "RENAME",
	INFO = "INFO",
	COPY = "COPY"
}

export enum LengthPanelItemModal {
	NONE = "NONE",
	WASTAGE = "WASTAGE",
	STYLE = "STYLE",
	DELETE = "DELETE",
	DROP = "DROP",
}

export enum AreaPanelItemModal {
	NONE = "NONE",
	HEIGHT = "WASTAGE",
	STYLE = "STYLE",
	DELETE = "DELETE",
}

export enum CountPanelItemModal {
	NONE = "NONE",
	STYLE = "STYLE",
	DELETE = "DELETE",
}

export enum ProjectSummaryModal {
	NONE = "NONE",
	STATUS = "STATUS",
	CONFIRMATION = "CONFIRMATION",
	ERROR = "ERROR"
}

export enum FileType {
	JPG = "jpg",
	JPEG = "jpeg",
	PNG = "png",
	GIF = "gif",
	BMP = "bmp",
	PDF = "pdf"
}

export enum ProjectType {
	PREPAID = "PREPAID",
	PROJECT = "PROJECT",
	SERVICE = "SERVICE"
}

export enum PromptModalType {
	UNSAVED_CHANGES = "UNSAVED_CHANGES",
	PLAN_UPLOAD_UNSAVED_CHANGES = "PLAN_UPLOAD_UNSAVED_CHANGES",
}

export enum SymbolGroupsModal {
	NONE = "NONE",
	DELETE = "DELETE",
	CREATE = "CREATE",
	RENAME = "RENAME"
}

export enum SymbolPacksModal {
	NONE = "NONE",
	DELETE = "DELETE",
	CREATE = "CREATE",
	RENAME = "RENAME"
}

export enum SymbolPackCreateStep {
	NO_SYMBOL_PACK = "NO_SYMBOL_PACK",
	DRAG_AND_DROP = "DRAG_AND_DROP",
	ZIP_FILES_LOADED = "ZIP_FILES_LOADED"
}

export enum ProjectTreeModal {
	NONE = "NONE",
	UPLOAD_PLAN = "UPLOAD_PLAN",
	CREATE_BLANK_DRAWING = "CREATE_BLANK_DRAWING",
	IMPORT_ATTACHMENT = "IMPORT_ATTACHMENT",
	CREATE_SECTION = "CREATE_SECTION",
	CREATE_COST_CENTER = "CREATE_COST_CENTER"
}

export enum DrawingType {
	TAKE_OFF = "TAKE_OFF",
	AS_BUILT = "AS_BUILT",
	ASSET_PLAN = "ASSET_PLAN"
}

export enum EditorPanelModal {
	SUMMARY = "SUMMARY",
	NONE = "NONE"
}

export enum MaterialModal {
	ADD_ITEMS = "ADD_ITEMS",
	MATERIAL_CHANGE = "MATERIAL_CHANGE"
}

export enum ProjectDetailsModal {
	SYNC_MATERIALS = "SYNC_MATERIALS",
	NONE = "NONE"
}

export enum SymbolRecognitionSummaryStatus {
	CREATED = "CREATED",
	IN_PROGRESS = "IN_PROGRESS",
	SUCCESS = "SUCCESS",
	FAILED = "FAILED"
}

export enum AsyncRequestStatus {
	IN_PROGRESS = "IN_PROGRESS",
	FINISHED_SUCCESSFULLY = "FINISHED_SUCCESSFULLY",
	FINISHED_WITH_ERRORS = "FINISHED_WITH_ERRORS"
}

export enum ConversionSourceType {
	Quote = "Quote",
	Recurring = "Recurring",
}
