import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import {UpdateDrawingApiDto} from "../../../models/restModels";
import {EditorDrawingData} from "../../project-drawings/features/editor/models/editor";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import {DrawingErrorTypes} from "../../project-drawings/features/editor/models/enums";
import {pages, useNavigator} from "../../navigator";

function useCreateAssetPlanFromAsBuilt(
	projectId: string,
	sectionId: string,
	costCenterId: string,
	drawingId: string,
	versionId: string,
	newDrawingName: string
) {
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {navigateTo} = useNavigator()
	const {t} = useTranslation();

	const errorTranslations = {
		[DrawingErrorTypes.VERSION_LOCK]: t(`projects.details.createAssetBuilder.createAssetBuilderErrDescVersionLock`),
		[DrawingErrorTypes.DRAWING_LOCK]: t(`projects.details.createAssetBuilder.createAssetBuilderErrDescDrawingLock`),
		[DrawingErrorTypes.DEFAULT]: t(`projects.details.createAssetBuilder.createAssetBuilderErrDesc`)
	}

	function handleError(err: any) {
		setSaveStatus(Status.ERROR);
		LoggerService.logError(err);
		let errorKey: DrawingErrorTypes;
		if (err?.response?.data?.exception?.includes("SourceDrawingVersionIsLockedException")) {
			errorKey = DrawingErrorTypes.VERSION_LOCK;
		}
		else if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
			errorKey = DrawingErrorTypes.DRAWING_LOCK;
		}
		else {
			errorKey = DrawingErrorTypes.DEFAULT;
		}
		NotificationService.errorNotification(t("common.error"), errorTranslations[errorKey])
	}

	function handleSuccess(newDrawingId: string, newVersionId: string) {
		setSaveStatus(Status.SUCCESS);
		NotificationService.successNotification(
			t("common.success"),
			t("projects.details.createAssetBuilder.createAssetBuilderSuccessDesc")
		)
		navigateTo(pages.projectDrawingPage(
			'jobs', projectId, sectionId, costCenterId, newDrawingId, newVersionId
		))
	}

	const createAssetPlan = () => {
		setSaveStatus(Status.LOADING);
		apiInstance.drawingsApi.postDrawingFromSource(projectId, sectionId, costCenterId, newDrawingName, drawingId,
			DrawingType.ASSET_PLAN)
			.then((drawingDetails) => {
				apiInstance.drawingsApi.fetchDrawingData(versionId)
					.then(drawingData => {
						const {data, measurements} = drawingData;
						const putDrawingData: UpdateDrawingApiDto = {
							data: (data) as EditorDrawingData,
							measurements,
							sourceVersionId: versionId
						};
						if (data) {
							apiInstance.drawingsApi.putDrawingData(drawingDetails.id, putDrawingData)
								.then((drawingVersion) => {
									handleSuccess(drawingDetails.id, drawingVersion.id);
								})
								.catch(handleError)
						}
					})
					.catch(handleError)
			})
			.catch(handleError)
	};

	return {createAssetPlan, saveStatus}
}

export default useCreateAssetPlanFromAsBuilt;
