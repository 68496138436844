import React from "react";
import {IconTakeoff} from "./IconTakeoff";
import "./iconAsBuilt.scss"
import {Colors} from "../../styles";

type Props = {
	color?: string,
	displayText?: string
}
export const IconWithText: React.FC<Props> = function({color = Colors.SIMPROBLUE, displayText = "AS BUILT"}) {

	return (
		<div className={"icon-as-built"}>
			<IconTakeoff color={color}/>
			<div className={"icon-as-built_signature"}>{displayText}</div>
		</div>
	)
}